import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom"
import axios from 'axios'
import { Row, Col, Button, PageHeader, Typography } from 'antd'
import { APADRINHE_URL_IMG } from '../constants'
import { withTheme } from 'styled-components';
import locales from './locales/Kid.json';

const { Title, Text } = Typography;

const InfoBlock = ({color, text = ' '}) => {
  return <div className="infoBlock" style={{backgroundColor:`${color}`}}>{text}</div>
}

class Kid extends Component {

  constructor(props) {
    super(props)

    this.state = {
      kid: {},
      loading: false
    }
  }

  getDayBirth = (fulldate) => {
    const date = new Date(fulldate+' 00:00:00')
    return date.toLocaleDateString('pt-BR', {month:'long', day: 'numeric'})
  }

  getYearProject = (fulldate) => {
    const date = new Date(fulldate)
    return date.getFullYear()
  }


  componentDidMount() {
    console.log('locales > ',locales)
    this.setState({
      loading: true
    })

    axios.get(`https://reconstruir.herokuapp.com/api/child/${this.props.match.params.id}`)
      .then( (response) => {
        // handle success
        this.setState({
          loading: false,
          kid: response.data
        })
      })
      .catch( (error) => {
        // handle error
        console.log('Error >>> ',error)
      })
  }

  render() {

    const { kid } = this.state
    const { theme } = this.props

    return (
      <React.Fragment>
        <div className="container">
          <PageHeader
            onBack={() => this.props.history.push('/')}
            title="Detalhes"
            subTitle={`Esta é a página d${kid.gender === 'M' ? 'o':'a'} ${kid.first_name}` }
          />
          { !this.state.loading && 
            <Row type="flex" justify="start" gutter={32}>
              <Col span={24}><Title level={2} style={ {color: theme.primaryColor} }>Olá, meu nome é {kid.first_name} e tenho {kid.age} anos</Title></Col>
              <Col xs={24} sm={24} md={6}>
                <img alt={kid.first_name} src={kid.profile_photo} width="100%" style={{ paddingTop: 8 }} />
              </Col>
              <Col sm={24} md={18}>
                
                <Text strong style={ {fontSize: '18px'} }>{kid.description}</Text>
                
                <Row type="flex" justify="space-around" gutter={16}>
                  <Col xs={24} sm={12} md={12} lg={6}><InfoBlock color="#5e9d59" text={`O dia do meu aniversário é ${this.getDayBirth(kid.birthdate)}`} /></Col>
                  <Col xs={24} sm={12} md={12} lg={6}><InfoBlock color="#f14a51" text={`A minha matéria preferida é ${kid.favorite_school_activity}`} /></Col>
                  <Col xs={24} sm={12} md={12} lg={6}><InfoBlock color="#55a3f4" text={`Participo do Projeto Reconstruir desde ${this.getYearProject(kid.in_project_since)}`} /></Col>
                  <Col xs={24} sm={12} md={12} lg={6}><InfoBlock color="#f8f633" text={`${kid.favorite_ong_activity} é a Oficina que mais gosto`} /></Col>
                </Row>
              </Col>
            </Row> 
          }
          <Row type="flex" justify="start" gutter={32} className="controls">
            { kid.total_sponsorships <= 2 &&
              <Button className="button-apadrinhe" type="primary" size="large" icon="heart" shape="round" onClick={() => this.props.history.push(`/apadrinhamento/${kid.id}/${kid.first_name}-${kid.gender}`)}>APADRINHE</Button>
            }
          </Row>
        </div>
        <Row style={ {backgroundColor: '#eaeaea'} }>
          <div className="container">
            <Row type="flex" justify="start" gutter={32}>
              <Col xs={24}>
                <Title level={2} style={ {color: theme.primaryColor} }>
                  APADRINHE UMA CRIANÇA!
                </Title>
              </Col>
              <Col xs={24} lg={16}>
                  <p style={ {fontSize: '18px', textAlign: 'justify'} }>
                    {locales[process.env.REACT_APP_COUNTRY || 'BR']['PROJET_DESCRIPTION_FIRST_HALF']}
                  </p>

                <p style={ {fontSize: '18px', textAlign: 'justify'} }>
                  {locales[process.env.REACT_APP_COUNTRY || 'BR']['PROJET_DESCRIPTION_SECOND_HALF']}
                </p>
              </Col>
              <Col xs={24} lg={8}>
                <img src={APADRINHE_URL_IMG} alt="seja um padrinho mantentenedor" width="100%" style={ {borderRadius: '4px'} } />
              </Col>
            </Row>
          </div>
        </Row>
      </React.Fragment>      
    );
  }
}

export default withRouter(withTheme(Kid));
