import styled from 'styled-components'

export const AppFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 16px;
  background: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.color};
  h3 {
    color: ${props => props.theme.color};
    text-decoration: underline;
  }
  p {
    margin: 0;
  }
  a {
    margin-right: 16px;
  }
`