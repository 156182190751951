export const THEME = process.env.REACT_APP_COUNTRY === 'AN' ? 'secondary' : 'main';
export const LOGO = process.env.REACT_APP_COUNTRY === 'AN' 
  ? require('./assets/img/logo-an.png') 
  : require('./assets/img/logo-new.jpg');
export const LINK_SITE = process.env.REACT_APP_COUNTRY === 'AN' ? 'https://www.umnovoreconstruirangola.com/' : 'https://www.projetoreconstruir.org.br/'
export const LINK_INSTAGRAM = process.env.REACT_APP_COUNTRY === 'AN' ? 'https://www.instagram.com/umnovoreconstruirangola' : 'https://www.instagram.com/projetoreconstruir' 
export const LINK_FACEBOOK = process.env.REACT_APP_COUNTRY === 'AN' ? 'https://www.facebook.com/umnovoreconstruirangola' : 'https://www.facebook.com/reconstruir' 
export const APADRINHE_URL_IMG = process.env.REACT_APP_COUNTRY === 'AN' 
  ? require('./assets/img/apadrinheAN.jpg') 
  : require('./assets/img/apadrinhe2.jpg');

export const LINK_LINKEDIN = 'https://www.linkedin.com/company/projeto-reconstruir/' 