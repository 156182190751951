import React, { Component } from 'react'
import { withRouter, Link } from "react-router-dom"
import { Row, Col, Button, PageHeader, Steps, Input, Checkbox, DatePicker } from 'antd'
import { Formik } from 'formik'
import axios from 'axios'
import moment from 'moment'
import InputMask from 'react-input-mask';
import { ReactComponent as Respect } from '../assets/img/respect.svg'
import { LINK_FACEBOOK, LINK_INSTAGRAM } from '../constants' 
import locale from 'antd/es/date-picker/locale/pt_BR';

const Step = Steps.Step;
//const RadioGroup = Radio.Group;
//const { Text } = Typography;

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.formPersonalData = React.createRef();
    this.state = {
      current: 0,
      email: '',
      birthdate: '',
      first_name: '',
      surname: '',
      cpf: '',
      telephone: '',
      address: '',
      money: 50,
      otherMoney: 50,
      rules: false,
      errors: {
        otherMoney: false
      },
      paymentMethod: 'BANK_TRANSFER',
      paymentMethodLabel: 'Transferencia'
    };
  }

  next(last) {
    if(last) {
      const data = {
        'first_name': this.state.first_name,
        'surname': this.state.surname,
        'email': this.state.email,
        'birthdate': this.state.birthdate.split("/").reverse().join("-"),
        'phone': this.state.telephone,
        'address': this.state.address,
        'cpf': this.state.cpf.replace(/\./g,'').replace('-',''),
        'child_id': this.props.match.params.id,
        'value': this.state.money !== -1 ? this.state.money : this.state.otherMoney ,
        'payment_method': this.state.paymentMethod
      }
      axios.post('https://reconstruir.herokuapp.com/api/sponsorship/request', data)
      .then( (response) => {
        // handle success
        const current = this.state.current + 1;
        this.setState({ current });
      })
      .catch( (error) => {
        // handle error
        this.setState({ current: 0 });
        const keys = Object.keys(error.response.data);
        keys.map((key,i)=> {
          this.formPersonalData.current.setFieldError(key,error.response.data[key])
          this.formPersonalData.current.setFieldTouched(key,true,false)
        }) 
      })
      return false
    }
    if(this.state.current === 0) {
      this.formPersonalData.current.handleSubmit()
      return false
    }
    if(this.state.current === 1) {
      if(this.state.money === -1 && this.state.otherMoney < 50) {
        this.setState({ 
          errors: {
            otherMoney: true
          }
         });  
        return false
      }

    }
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }
 
  onChange = (val) => {
    this.setState((state) => {
      return {
        money: val,
        otherMoney: val > 0 && 50 || state.otherMoney,
        errors: {
          otherMoney: false
        }
      }
    });
  }

  handleInput = (event) => {
    if(event && event.target) {
      console.log(event.target.value)
      this.setState({
        otherMoney: event.target.value,
        // errors: {
        //   otherMoney: event.target.value >= 50 && false || true
        // }
      });
    }
  }

  onChangePayment = (val, label) => {
    this.setState({
      paymentMethod: val,
      paymentMethodLabel: label
    });
  }

  render() {
    const { current, email, birthdate, first_name, surname, cpf, telephone, address, rules, errors } = this.state

    return (
      <div className="container">
        { current < 4 && 
          <PageHeader
            onBack={() => this.props.history.push(`/crianca/${this.props.match.params.id}`)}
            title="Apadrinhamento"
            subTitle={`Falta pouco para tornar-se padrinho d${this.props.match.params.sexo === 'M' ? 'o':'a'} ${this.props.match.params.nome}` }
          />
        }
        <Steps size="small" current={current} >
          <Step title="Passo 1" description="Insira seus dados pessoais." />
          <Step title="Passo 2" description="Escolha um valor de doação." />
          <Step title="Passo 3" description="Escolha o método de pagamento." />
          <Step title="Passo 4" description="Verifique os dados inseridos." />
        </Steps>
        <div className="steps-content">
          { current === 0 && 
            <div className="form-container">
              <Formik
                ref={this.formPersonalData}
                initialValues={{ 
                  email: email, 
                  first_name: first_name, 
                  surname: surname, 
                  birthdate: birthdate,
                  cpf: cpf, 
                  telephone: telephone, 
                  address: address, 
                  rules: rules, 
                  errors 
                }}
                validate={values => {
                  let errors = {};
                  if (!values.email) {
                    errors.email = 'Campo obrigatório';
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                  ) {
                    errors.email = 'E-mail inválido';
                  }
                  if (!values.first_name) {
                    errors.first_name = 'Campo obrigatório';
                  }
                  if (!values.surname) {
                    errors.surname = 'Campo obrigatório';
                  }
                  if (!values.cpf) {
                    errors.cpf = 'Campo obrigatório';
                  } else if ( 
                    !/^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/i.test(values.cpf)
                  ) {
                    errors.cpf = 'Cpf invalido';
                  }
                  if (!values.telephone) {
                    errors.telephone = 'Campo obrigatório';
                  }
                  if (!values.address) {
                    errors.address = 'Campo obrigatório';
                  }
                  if (!values.rules) {
                    errors.rules = 'Campo obrigatório';
                  }
                  return errors;
                }}
                onSubmit={(values) => {
                  this.setState({
                    current: 1,
                    email: values.email,
                    birthdate: values.birthdate,
                    first_name: values.first_name,
                    surname: values.surname,
                    rules: values.rules,
                    cpf: values.cpf,
                    telephone: values.telephone,
                    address: values.address
                  })
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  //handleBlur,
                  setFieldValue
                  //handleSubmit,
                  //isSubmitting,
                  /* and other goodies */
                }) => (
                  <form >
                    <h2>Precisamos que você cadastre alguns dados:</h2>
                    <div className="input-container">
                      <label>E-mail*</label>
                      <Input
                        size="large"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                      />
                      {errors.email && touched.email && <p className="error-field">{errors.email}</p>}
                    </div>
                    <div className="input-container">
                      <label>Nome*</label>
                      <Input
                        size="large"
                        type="text"
                        name="first_name"
                        onChange={handleChange}
                        value={values.first_name}
                      />
                      {errors.first_name && touched.first_name && <p className="error-field">{errors.first_name}</p>}
                    </div>
                    <div className="input-container">
                      <label>Sobrenome*</label>
                      <Input
                        size="large"
                        type="text"
                        name="surname"
                        onChange={handleChange}
                        value={values.surname}
                      />
                      {errors.surname && touched.surname && <p className="error-field">{errors.surname}</p>}
                    </div>
                    <div className="input-container">
                      <label>CPF*</label>
                      <InputMask mask="999.999.999-99" value={values.cpf} onChange={handleChange} disabled={false}>
                        {(inputProps) => <Input
                            {...inputProps}
                            size="large"
                            type="text"
                            name="cpf"
                            // onChange={handleChange}
                            // value={values.cpf}
                          />
                        }
                      </InputMask>
                      {errors.cpf && touched.cpf && <p className="error-field">{errors.cpf}</p>}
                    </div>
                    <div className="input-container">
                      <label>Data de nascimento</label>
                      <DatePicker
                        size="large"
                        style={{width: '100%'}}
                        locale={locale}
                        format={'DD/MM/YYYY'}
                        defaultPickerValue={moment().subtract(18,'year')}
                        disabledDate={current => current > moment().subtract(18,'year')}
                        placeholder={'DD/MM/YYYY'}
                        //onChange={(e,val) => handleChange(new Date(val))}
                        onChange={(date, dateString) => setFieldValue('birthdate', dateString)}
                        //value={values.birthdate}
                        name="birthdate"
                        showToday={false}
                      />
                      {errors.birthdate && touched.birthdate && <p className="error-field">{errors.birthdate}</p>}
                    </div>
                    <div className="input-container">
                      <label>Telefone*</label>
                      <Input
                        size="large"
                        type="text"
                        name="telephone"
                        onChange={handleChange}
                        value={values.telephone}
                      />
                      {errors.telephone && touched.telephone && <p className="error-field">{errors.telephone}</p>}
                    </div>
                    <div className="input-container">
                      <label>Endereço*</label>
                      <Input
                        size="large"
                        type="text"
                        name="address"
                        onChange={handleChange}
                        value={values.address}
                      />
                      {errors.address && touched.address && <p className="error-field">{errors.address}</p>}
                    </div>
                    <div className="input-container">
                      <Checkbox 
                        onChange={handleChange}
                        name="rules"
                        required 
                        checked={values.rules}
                      > 
                        <a 
                          onClick={() => window.open(window.location.origin+'/Regras_Informacoes_Apadrinhamento_2021.pdf')}
                          style={{textDecoration: 'underline'}}
                        >
                            Li e concordo com as regras do Programa de Apadrinhamento.
                        </a>
                      </Checkbox>
                      {errors.rules && touched.rules && <p className="error-field">{errors.rules}</p>}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          }
          { current === 1 && 
            <div className="form-container">
              <h2>Selecione um valor mensal:</h2>
              <div 
                className="input-selection" 
                onClick={() => this.onChange(50)} 
                style={this.state.money === 50 ? {borderColor: 'red'} : {}}
              >
                <p className="primary-label">R$50 por mês</p>
                <p className="secondary-label">(R$1.66 por dia)</p>
              </div>
              <div className="input-selection" onClick={() => this.onChange(70)} style={this.state.money === 70 ? {borderColor: 'red'} : {}}>
                <p className="primary-label">R$70 por mês</p>
                <p className="secondary-label">(R$2.33 por dia)</p>
              </div>
              <div className="input-selection" onClick={() => this.onChange(100)} style={this.state.money === 100 ? {borderColor: 'red'} : {}}>
                <p className="primary-label">R$100 por mês</p>
                <p className="secondary-label">(R$3.33 por dia)</p>
              </div>
              <div className="input-selection" onClick={() => this.onChange(-1)} style={this.state.money === -1 ? {borderColor: 'red'} : {}}>
                <p className="primary-label">Outro valor mensal</p>
                <Input
                  min={50}
                  prefix="R$"
                  type="number"
                  defaultValue={this.state.otherMoney}
                  style={{ width: '100px' }}
                  //value={this.state.otherMoney}
                  //formatter={value => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  //parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={this.handleInput}
                />
                {/* <Input type="number" value={this.state.otherMoney} onChange={this.handleInput} style={ {textAlign: 'center'} } /> */}
                <p className="secondary-label" style={this.state.errors.otherMoney ? {color: 'red'} : {}}>(valor mínimo R$ 50)</p>
              </div>
            </div>
          }
          { current === 2 && 
            <div className="form-container">
              <h2>Selecione a melhor opção de pagamento:</h2>
              <div 
                className="input-selection" 
                onClick={() => this.onChangePayment('BANK_TRANSFER', 'Transferencia')} 
                style={this.state.paymentMethod === 'BANK_TRANSFER' ? {borderColor: 'red'} : {}}>
                <p className="primary-label">Transferencia</p>
              </div>
              <div 
                className="input-selection" 
                onClick={() => this.onChangePayment('BANK_SLIP', 'Boleto')} 
                style={this.state.paymentMethod === 'BANK_SLIP' ? {borderColor: 'red'} : {}}
              >
                <p className="primary-label">Boleto</p>
                <p className="secondary-label">(tarifa de R$2.15)</p>
              </div>
              <div 
                className="input-selection" 
                onClick={() => this.onChangePayment('PAG_SEGURO','PagSeguro')} 
                style={this.state.paymentMethod === 'PAG_SEGURO' ? {borderColor: 'red'} : {}}>
                <p className="primary-label">PagSeguro</p>
                <p className="secondary-label">(tarifa de R$2.90)</p>
              </div>
              {/* <div className="input-selection" onClick={() => this.onChangePayment(4,'Cartão')} style={this.state.paymentMethod === 4 ? {borderColor: 'red'} : {}}>
                <p className="primary-label">Cartão</p>
              </div> */}
            </div>
          }
          { current === 3 && 
            <div>
              <h2>Aqui os dados inseridos:</h2>
              <div className="confirmation-container">
                
                <Row type="flex" justify="start" gutter={16}>
                  <Col span={12}><p className="confirmation-label">E-mail:</p></Col>
                  <Col span={12}><p className="confirmation-value">{this.state.email}</p></Col>
                </Row>
                <Row type="flex" justify="start" gutter={16}>
                  <Col span={12}><p className="confirmation-label">Nome completo:</p></Col>
                  <Col span={12}><p className="confirmation-value">{this.state.first_name} {this.state.surname}</p></Col>
                </Row>
                <Row type="flex" justify="start" gutter={16}>
                  <Col span={12}><p className="confirmation-label">CPF:</p></Col>
                  <Col span={12}><p className="confirmation-value">{this.state.cpf}</p></Col>
                </Row>
                <Row type="flex" justify="start" gutter={16}>
                  <Col span={12}><p className="confirmation-label">Telefone:</p></Col>
                  <Col span={12}><p className="confirmation-value">{this.state.telephone}</p></Col>
                </Row>
                <Row type="flex" justify="start" gutter={16}>
                  <Col span={12}><p className="confirmation-label">Endereço:</p></Col>
                  <Col span={12}><p className="confirmation-value">{this.state.address}</p></Col>
                </Row>
                <Row type="flex" justify="start" gutter={16}>
                  <Col span={12}><p className="confirmation-label">Valor mensal:</p></Col>
                  <Col span={12}><p className="confirmation-value">R${this.state.money > -1 && this.state.money || this.state.otherMoney }</p></Col>
                </Row>
                <Row type="flex" justify="start" gutter={16}>
                  <Col span={12}><p className="confirmation-label">Método de pagamento:</p></Col>
                  <Col span={12}><p className="confirmation-value">{this.state.paymentMethodLabel}</p></Col>
                </Row>
              </div>
              
            </div>
          }
          { current === 4 && 
            <div>
              <Respect width="80" fill="#ff4300" />
              <h2>
                Ficamos felizes com o seu desejo em apadrinhar uma das nossas crianças.<br/>
                Juntos podemos fazer a diferença. Agora falta pouco! <br/>
                Entraremos em contato para concluirmos o seu apadrinhamento.<br/><br/>
                Enquanto isso você pode acompanhar as atividades de sua criança/adolescente através das nossas redes sociais:<br/>
                <a href={LINK_FACEBOOK} target="_blank">{LINK_FACEBOOK}</a> <br/>
                <a href={LINK_INSTAGRAM} target="_blank">{LINK_INSTAGRAM}</a><br/><br/>
              </h2>
              <Link to="/">Volta para Home</Link>
              
            </div>
          }
        </div>
        <div className="steps-action">
          {
            current > 0 && current <= 3
            && (
            <Button style={{ marginRight: 8 }} onClick={() => this.prev()}>
              Voltar
            </Button>
            )
          }
          {
            current < 3
            && <Button type="primary" onClick={() => this.next()}>Próximo</Button>
          }
          {
            current === 3
            && <Button type="primary" onClick={() => this.next(true)}>Finalizar</Button>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(Checkout)
