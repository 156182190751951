module.exports = {
  main: {
    color: 'white',
    backgroundColor: '#4559fb',
    primaryColor: '#fe5500'
  },
  secondary: {
    color: 'white',
    backgroundColor: '#000',
    primaryColor: '#ed0e0e'
  }
}
//export default theme